import CategoryMultiSelect from "@components/CustomTypography/CategoryMultiSelect/CategoryMultiSelect"
import Pagination from "@components/CustomTypography/Pagination/Pagination"
import CustomSelect from "@components/Theme/Select/CustomSelect/CustomSelect"
import {CategoriesModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React, {ReactNode, useEffect, useState} from "react"

import PostsNotFound from "../PostsNotFound/PostsNotFound"

type SelectedOptions = {
	[key: string]: string[]
}
type DataItemType = {
	categories: CategoriesModuleType[]
	[key: string]: any
}

type Props = {
	stylesForFilters?: string
	stylesForFilterBox?: string
	data: DataItemType[]
	filters: CategoriesModuleType[]
	children: ReactNode
	withoutPagination?: boolean
	changeData: (data: DataItemType[]) => void
	postType: string
}

const PaginationWithFiltes = (props: Props) => {
	const router = useRouter()
	const {locale} = router

	const [posts, setPosts] = useState<DataItemType[]>(props.data)

	const [currentPage, setCurrentPage] = useState(1)
	const [postsPerPage] = useState(8)

	const indexOfLastPost = currentPage * postsPerPage
	const indexOfFirstPost = indexOfLastPost - postsPerPage
	const currentPosts = props.withoutPagination
		? posts
		: posts.slice(indexOfFirstPost, indexOfLastPost)

	const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

	const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})

	const handleOptionChange = (selectedOptions: {[key: string]: string[]}) => {
		setSelectedOptions((prevSelectedOptions) => ({
			...prevSelectedOptions,
			...selectedOptions,
		}))

		setPosts(filterDataBySelectedOptions(props.data, selectedOptions))
	}

	function filterDataBySelectedOptions(
		data: DataItemType[],
		selectedCategories: SelectedOptions,
	): DataItemType[] {
		const selectedKeys = Object.keys(selectedCategories)

		return data.filter((item) => {
			const {categories} = item
			const conditionalArray = []

			categories.forEach((element) => {
				const categoryName = Object.keys(element)[0]

				if (selectedKeys.includes(categoryName)) {
					const selectedArray = selectedCategories[categoryName]
					const temporaryArray = element[categoryName].options
						.filter((element2) =>
							selectedArray.includes(element2.slug),
						)
						.map((element2) => element2.slug)

					if (temporaryArray.length > 0) {
						conditionalArray.push(categoryName)
					}
				}
			})

			return conditionalArray.length === selectedKeys.length
		})
	}

	useEffect(() => {
		setCurrentPage(1)
	}, [posts])

	useEffect(() => {
		setPosts(filterDataBySelectedOptions(props.data, selectedOptions))
		props.changeData(currentPosts)
	}, [selectedOptions, locale])

	useEffect(() => {
		props.changeData(currentPosts)
	}, [props.data, posts, currentPage])

	return (
		<>
			<div className={`${props.stylesForFilterBox}`}>
				{props.filters.map(
					(element: CategoriesModuleType, index: number) => {
						const indexName = Object.keys(element)[0] // Get the index/key of the filter object
						const categoryModules = element[indexName]

						return (
							<React.Fragment key={index}>
								{categoryModules.type === "boxedSelect" && (
									<CategoryMultiSelect
										className={props.stylesForFilters}
										label={indexName}
										name={indexName}
										options={categoryModules.options}
										onChange={handleOptionChange}
									/>
								)}
								{(!categoryModules.type ||
									categoryModules.type === "select") && (
									<CustomSelect
										className={props.stylesForFilters}
										label={indexName}
										name={indexName}
										options={categoryModules.options}
										enableAll
										onChange={handleOptionChange}
									/>
								)}
							</React.Fragment>
						)
					},
				)}
			</div>

			{posts.length > 0 && props.children}
			{posts.length === 0 && <PostsNotFound postType={props.postType} />}

			{!props.withoutPagination && (
				<Pagination
					currentPage={currentPage}
					itemsPerPage={postsPerPage}
					paginate={paginate}
					totalItems={posts.length}
				/>
			)}
		</>
	)
}

export default PaginationWithFiltes
