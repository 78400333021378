import {Translate} from "@components/Theme/Translate/Translate"
import toCamelCase from "@helpers/toCamelCase"
import {CategoryModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React, {useEffect, useRef, useState} from "react"

import styles from "./CategoryMultiSelect.module.scss"

type CategoryMultiSelectProps = {
	options: CategoryModuleType[]
	name: string
	className?: string
	label?: string
	onChange: (selectedOptions: {[key: string]: string[]}) => void
}

const CategoryMultiSelect: React.FC<CategoryMultiSelectProps> = ({
	options,
	className,
	name,
	label,
	onChange,
}) => {
	const nameCamel = toCamelCase(name)
	const allCategorySlugs = options.map((category) => category.slug)
	const [selectedCategories, setSelectedCategories] =
		useState<string[]>(allCategorySlugs)
	const [isApplying, setIsApplying] = useState(false)
	const timeoutRef = useRef<number>()
	const router = useRouter()

	useEffect(() => {
		if (isApplying) {
			onChange({[nameCamel]: selectedCategories})
			setIsApplying(false)
		}
	}, [isApplying, selectedCategories, onChange])

	useEffect(() => {
		const initialCategories = router.query[nameCamel] as string
		if (initialCategories) {
			setSelectedCategories(
				initialCategories
					? initialCategories.split(",")
					: [initialCategories],
			)
			setIsApplying(true)
		}
	}, [router.query[nameCamel], options])

	const handleCategoryChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const categorySlug = event.target.value
		if (event.target.checked) {
			setSelectedCategories([...selectedCategories, categorySlug])
		} else {
			setSelectedCategories(
				selectedCategories.filter((slug) => slug !== categorySlug),
			)
		}
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
		}
		setIsApplying(true)
		timeoutRef.current = window.setTimeout(() => setIsApplying(false), 1000)
	}

	const handleSelectAll = () => {
		// const allCategorySlugs = options.map((category) => category.slug)
		setSelectedCategories(allCategorySlugs)
		setIsApplying(true)
		timeoutRef.current = window.setTimeout(() => setIsApplying(false), 1000)
	}

	const handleDisableAll = () => {
		setSelectedCategories([])
		setIsApplying(true)
		timeoutRef.current = window.setTimeout(() => setIsApplying(false), 1000)
	}

	return (
		<div className={`${styles.categoryMultiSelect}  ${className}`}>
			{options.map((category) => (
				<label key={category.id} className={styles.categoryLabel}>
					<input
						checked={selectedCategories.includes(category.slug)}
						className={styles.categoryInput}
						type={"checkbox"}
						value={category.slug}
						onChange={handleCategoryChange}
					/>
					<span className={styles.categoryTitle}>
						<Translate capitalize>{category.title}</Translate>
					</span>
				</label>
			))}
			<div className={styles.divider} />
			<button
				className={`${styles.categorySelectAll}`}
				onClick={handleSelectAll}
			>
				<Translate capitalize>Show All</Translate>
			</button>
			<button
				className={`${styles.categorySelectAll}`}
				onClick={handleDisableAll}
			>
				<Translate capitalize>Clear Selection</Translate>
			</button>
		</div>
	)
}

export default CategoryMultiSelect
