import Description from "@components/CustomTypography/Description/Description"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import toCamelCase from "@helpers/toCamelCase"
import {useRouter} from "next/router"
import React, {useEffect, useRef, useState} from "react"
import {CSSTransition} from "react-transition-group"

import {Translate} from "../../Translate/Translate"

import styles from "./CustomeSelect.module.scss"

export type option = {id: number; title: string; slug: string}

interface SelectProps {
	name: string
	label?: string
	className?: string
	enableAll: boolean
	activeValue?: string[]
	options: option[]
	onChange: (selectedOptions: {[key: string]: string[]}) => void
}

const CustomSelect: React.FC<SelectProps> = ({
	className,
	options,
	name,
	label,
	onChange,
	enableAll,
	activeValue,
}) => {
	const nameCamel = toCamelCase(name)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState<string[]>(
		activeValue || [],
	)
	const menuRef = useRef<HTMLDivElement>(null)
	const router = useRouter()

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const handleOptionClick = (option: option) => {
		const updatedOptions: string[] = [option.slug]
		setSelectedOptions(updatedOptions)
		onChange({[nameCamel]: updatedOptions})
		setIsMenuOpen(false) // Close the menu when an option is selected
	}

	const handleSelectAll = () => {
		const allOptionSlugs = options.map((option) => option.slug)
		setSelectedOptions(allOptionSlugs)
		onChange({[nameCamel]: allOptionSlugs})
		setIsMenuOpen(false) // Close the menu when "Select All" is clicked
	}

	/**
	 * Hook that alerts clicks outside of the passed ref
	 */
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsMenuOpen(false)
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [menuRef])

	useEffect(() => {
		const query = router.query[nameCamel] // Get query value based on the name prop
		if (query) {
			const selectedOption = options.find(
				(option) => option.slug === query,
			)
			if (selectedOption) {
				setSelectedOptions([selectedOption.slug])
				onChange({[nameCamel]: [selectedOption.slug]})
			}
		}
	}, [router.query[nameCamel], options])

	useEffect(() => {
		if (activeValue) {
			const selectedOption = options.find(
				(option) => option.slug === activeValue[0],
			)
			if (selectedOption) {
				setSelectedOptions([selectedOption.slug])
				onChange({[nameCamel]: [selectedOption.slug]})
			}
		}
	}, [activeValue])

	return (
		<div className={`${className}`}>
			{label && (
				<Paragraph
					content={<Translate capitalizeFirst>{label}</Translate>}
					fontWeight={400}
					size={"XS"}
				/>
			)}
			<div ref={menuRef} className={`${styles.languageSwitcher} `}>
				<Description
					className={`${styles.selectedOption} ${className} ${
						isMenuOpen ? styles.active : ""
					}`}
					content={
						<>
							{enableAll &&
							(selectedOptions.length > 1 ||
								selectedOptions.length === 0) ? (
								<Translate capitalizeFirst>All</Translate>
							) : (
								options.find(
									(element) =>
										element.slug === selectedOptions[0],
								)?.title
							)}
							<span />
						</>
					}
					size={"M"}
					onClick={toggleMenu}
				/>
				<CSSTransition
					classNames={{
						enter: styles.enter,
						enterActive: styles.enterActive,
						exit: styles.exit,
						exitActive: styles.exitActive,
					}}
					in={isMenuOpen}
					timeout={200}
					unmountOnExit
				>
					<div
						className={`${styles.selectMenu} ${
							isMenuOpen ? styles.show : ""
						}`}
					>
						{enableAll && (
							<Description
								className={`${styles.option} ${
									selectedOptions.length > 1
										? styles.active
										: ""
								}`}
								content={"Select All"}
								size={"M"}
								capitalize
								onClick={handleSelectAll}
							/>
						)}

						{options.map((option) => (
							<Description
								key={option.id}
								className={`${styles.option} ${
									selectedOptions.includes(option.slug) &&
									selectedOptions.length === 1
										? styles.active
										: ""
								}`}
								content={option.title}
								size={"M"}
								capitalize
								onClick={() => handleOptionClick(option)}
							/>
						))}
					</div>
				</CSSTransition>
			</div>
		</div>
	)
}

export default CustomSelect
