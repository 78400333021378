import React from "react"

import styles from "./Pagination.module.scss"

type Props = {
	itemsPerPage: number
	totalItems: number
	paginate: (pageNumber: number) => void
	currentPage: number
}

const Pagination = (props: Props) => {
	const pageNumbers = []

	for (
		let i = 1;
		i <= Math.ceil(props.totalItems / props.itemsPerPage);
		i++
	) {
		pageNumbers.push(i)
	}

	const handlePageClick = (pageNumber: number) => {
		props.paginate(pageNumber)
		if (props.currentPage !== pageNumber) {
			const section = document.getElementById("startOfPagination")
			if (section) {
				section.scrollIntoView({behavior: "smooth"}) // scroll to the element
			} else {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				})
			}
		}
	}

	return (
		<nav>
			<ul className={styles.pagination}>
				{pageNumbers.map((number) => (
					<li key={number} className={styles.pageItem}>
						<button
							className={`${styles.pageLink} ${
								props.currentPage === number
									? styles.active
									: ""
							}`}
							onClick={() => handlePageClick(number)}
						>
							{number}
						</button>
					</li>
				))}
			</ul>
		</nav>
	)
}

export default Pagination
