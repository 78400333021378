import {Translate} from "@components/Theme/Translate/Translate"
import React from "react"

import styles from "./PostsNotFound.module.scss"

type Props = {
	postType: string
}

const PostsNotFound = (props: Props) => {
	return (
		<div className={styles.noBlogPostsFound}>
			<h2 className={styles.noBlogPostsFoundTitle}>
				<Translate>No</Translate>{" "}
				<Translate>{props.postType}</Translate>{" "}
				<Translate>Found</Translate>
			</h2>
			<p className={styles.noBlogPostsFoundText}>
				<Translate>Sorry, we couldn't find any </Translate>
				<Translate>{`${props.postType.toLowerCase()} ${" "}`}</Translate>
				<Translate>
					in this category. Please try a different category.
				</Translate>
			</p>
		</div>
	)
}

export default PostsNotFound
